.mediaContainer {
  aspect-ratio: 4/3;
  border-radius: var(--border-radius-l);
  overflow: hidden;
  position: relative;
}

.itemsContainer {
  -ms-overflow-style: none;
  display: flex;
  flex-flow: row nowrap;
  gap: 1.6rem;
  margin-right: calc(-1*var(--max-width-padding-s));
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--viewport-m) {
    align-items: top;
    display: grid;
    gap: 3.2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.titleContainer {
  margin-bottom: 4rem;
}

.container {
  text-decoration: none;
  align-self: start;
  min-width: 90%;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;

  @media (--viewport-m) {
    min-width: initial;
  }
}

.label {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  margin-top: 1.2rem;
}
